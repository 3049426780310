import React, { useState, useEffect } from "react";
import Router from "./Routes";
// import Navbar from './components/Navbar'
import Footer from './components/Footer'

const redirectToNewURL = () => {
    window.location.href = "https://www.michelsarazin-ayurveda-aix.com";
}

function getWindowDimensions() {
	const { innerWidth: width } = window;
	return {
		width,
	};
}

function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

const App = () => {
 redirectToNewURL();
 const {width} = useWindowDimensions();
 return (
  <div>
   {/* <Navbar /> */}
   <Router windowWidth={width}/>
   {/* <p>width {width}</p> */}
   <Footer />
  </div>
 );
};

export default App;
